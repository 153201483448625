
import request from '../../utils/request'
// 用户管理
export function getUser(data = {}) {
  return request({
    url: `/website/user/list`,
    method: 'get',
    params: {
      limit: data.pageSize,
      page: data.pageNum,
      name: data.name
    }
  })
}
// 修改 提交
export function getSubmit(data = {}) {
  return request({
    url: data.userId ? '/website/user/update' : '/website/user/save',
    method: data.userId ? 'put' : 'post',
    data
  })
}
// 删除
export function handlDel(data = []) {
  return request({
    url: '/website/user/delete',
    method: 'DELETE',
    data
  })
}
