<template>
  <div class="home">
    <el-dialog
      :title="`${formType === 1 ? '编辑' : '新增'}用户`"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="form" size="small">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.username" autocomplete="off" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off" />
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">
          <el-input v-model="form.roleName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-input v-model="form.userStatus" autocomplete="off" />
        </el-form-item>
        <el-form-item label="创建时间" :label-width="formLabelWidth">
          <el-input v-model="form.loginTime" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="subLoading" size="small" @click="handleSubmit()">确 定</el-button>
      </div>
    </el-dialog>
    <div class="table">
      <div class="header">
        <el-input
          v-model="query.name"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width:174px"
          size="mini"
        />
        <el-button type="primary" size="mini" @click="gettableData">查询</el-button>
        <div style="float:right;">
          <el-button type="success" size="mini" @click="handleEdit()">新增</el-button>
          <el-button type="danger" size="mini" @click="BatchhandleDelet()">批量删除</el-button>
        </div>
      </div>

      <div>
        <el-table :data="tableData" stripe fit @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="username" label="姓名" width="180" />
          <el-table-column prop="phone" label="手机号" width="180" />
          <el-table-column prop="roleName" label="角色" width="200" />
          <el-table-column prop="userStatus" label="状态" width="180">
            <template slot-scope="{row}">
              <div v-if="row.userStatus === 1">正常</div>
            </template>
          </el-table-column>
          <el-table-column prop="loginTime" label="创建时间" width="200" />
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click="handleEdit(scope.row)"
              >编辑</el-button>
              <el-button type="text" size="small" @click="handleDelet(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="block">
          <el-pagination
            :current-page="query.pageNum"
            :page-sizes="[5, 10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            size="small"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser, getSubmit, handlDel } from '../../api/admin/user'
export default {
  data() {
    return {
      tableData: [],
      query: {
        pageNum: '1',
        pageSize: '20',
        name: ''
      },
      total: 0,
      dialogFormVisible: false,
      form: {
        username: '',
        password: '',
        phone: '',
        roleName: '',
        userStatus: '',
        loginTime: ''
      },
      subLoading: false,
      formType: 0, // 0新增 1编辑
      ids: [],
      roleName: []
    }
  },
  created() {
    this.gettableData()
  },
  methods: {
    gettableData() {
      getUser(this.query)
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.items
          this.total = res.data.total
        })
    },
    // 编辑
    handleEdit(row) {
      // 0新增 1编辑
      this.formType = row ? 1 : 0
      console.log('handleEdit', row)
      if (row) {
        this.form = JSON.parse(JSON.stringify(row))
      }
      this.dialogFormVisible = true
    },
    // 点击取消，弹窗关闭
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
    },
    // 提交
    handleSubmit() {
      this.subLoading = true
      getSubmit(this.form).then(res => {
        this.subLoading = false
        if (this.formType === 0) {
          this.query.pageNum = 1
        }
        this.handleClose()
        this.gettableData()
      }).catch(e => {
        this.subLoading = false
      })
    },
    // 删除
    handleDelet(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel([row.userId])
            .then(res => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.gettableData()
            })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 批量删除
    BatchhandleDelet() {
      if (this.ids.length <= 0) {
        this.$notify({
          title: '警告',
          message: '请选择要删除的行',
          duration: 1500,
          type: 'warning'
        })
        return
      }
      this.newsLoading = true
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel(this.ids)
            .then(res => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.gettableData()
            })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 选取某一行数据
    handleSelectionChange(val) {
      console.log('handleSelectionChange', val)
      this.ids = val.map(o => o.userId)
      console.log('ids', this.ids)
    },
    // 分页
    handleSizeChange(e) {
      console.log('handleSizeChange', e)
      this.query.pageSize = e
      this.gettableData()
    },
    handleCurrentChange(e) {
      console.log('handleCurrentChange', e)
      this.query.pageNum = e
      this.gettableData()
    }
  }

}
</script>

<style lang="scss">
.table {
  border: 1px solid #ddd;
  .header {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    .el-button {
      margin-left: 10px;
    }
  }

}

/* .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  margin: 10px;
} */
.el-pagination {
  margin: 10px;
  text-align: center;
}

</style>
